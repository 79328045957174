/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  display: flex;
  align-items: center;
  color: #575757;
  cursor: pointer; }
  .Component .Icon {
    opacity: 0; }
  .Component input {
    display: none; }
    .Component input:checked + .Checkbox {
      background-color: var(--accent-color);
      border: 1px solid transparent; }
      .Component input:checked + .Checkbox .Icon {
        opacity: 1 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .Component input:checked + .Checkbox:before, .Component input:checked + .Checkbox:after {
        opacity: 1; }
    .Component input:checked ~ .Label {
      color: #575757 !important; }
  .Component .Checkbox {
    display: block;
    width: 22px;
    height: 22px;
    border: 1px solid #868686;
    position: relative;
    border-radius: 2px; }
    @media (max-width: 575.8px) {
      .Component .Checkbox {
        margin-right: 5px; } }

.Label {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center; }
  @media (max-width: 575.8px) {
    .Label {
      font-size: 14px; } }

.ComponentRoller input:checked + .Checkbox {
  opacity: 1; }
  .ComponentRoller input:checked + .Checkbox:before {
    transform: translateX(20px) translateY(-50%); }
    @media (max-width: 575.8px) {
      .ComponentRoller input:checked + .Checkbox:before {
        transform: translateX(16px) translateY(-50%); } }

.ComponentRoller .Checkbox {
  width: 40px;
  height: 22px;
  background-color: #faf8f5;
  border-radius: 60px;
  transition: background-color .25s ease-in-out;
  border: 1px solid transparent; }
  @media (max-width: 575.8px) {
    .ComponentRoller .Checkbox {
      width: 36px;
      height: 18px; } }
  .ComponentRoller .Checkbox:before {
    display: block;
    position: absolute;
    content: '';
    opacity: 1;
    background-color: #FFFFFF;
    width: 13px;
    height: 13px;
    top: 50%;
    border-radius: 10em;
    transform: translateX(6px) translateY(-50%);
    transition: transform .25s ease-in-out; }
    @media (max-width: 575.8px) {
      .ComponentRoller .Checkbox:before {
        width: 11px;
        height: 11px; } }
  .ComponentRoller .Checkbox:after {
    display: none !important; }

.ComponentRoller .Label {
  font-size: 14px; }
