/*START CSS RESET*/

html {
  scroll-padding-top: 100px;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

*, *:before, *:after {
  box-sizing: border-box;
}

html, button {
  line-height: 1;
}

body, button, input, textarea {
  font-family: var(--font-family);
  line-height: 1;
  font-size: 14px;
  text-rendering: optimizeLegibility !important;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
}

a {
  text-decoration: none;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

:focus {
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

/* END RESET CSS */

input {
  transition: 0.25s border-color;
}


.select dt:before {
  content: '';
  display: block;
  position: absolute;
}

.select dt:before {
  top: 50%;
  transform: translateY(-50%);
}

.event-cover__img, .event-cover__buying.multiple-tickets .blur-bg svg {
  left: 50%;
  transform: translateX(-50%);
}

.event-info__socials a svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cf:before, .cf:after {
  content: '.';
  display: block;
  overflow: hidden;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0;
}

.cf:after {
  clear: both;
}

.wrap {
  max-width: 1160px;
  margin: auto;
}

.pointer {
  cursor: pointer;
}

.hide {
  display: none !important;
}

body {
  overflow-x: hidden;
  background: #fff;
}

.wrap {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
}

header.page-header {
  padding: 8px 0;
  // position: fixed;
  width: 100%;
  top: 0;
  left: 0;

  .wrap {
    max-width: 1200px;
    align-items: center;

    & > * {
      flex-basis: calc(100% / 3)
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;

      & > * {
        flex-basis: 100%;
      }
    }
  }


  .actions {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 768px) {
      justify-content: space-around;
    }

    .profile-link {
      font-size: 15px;
      height: 35px;
      display: flex;
      align-items: center;
      margin: 0 10px;
      font-weight: bold;
    }

    .sign-out {
      font-size: 15px;
      transition: 0.2s;
      opacity: 0.6;
      margin-right: 30px;
      font-weight: bold;

      @media (max-width: 768px) {
        margin: 0;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .logo {
    margin-left: calc(100% / 3);
    text-align: center;
    font-weight: 700;
    color: #1e1d31;
    font-size: 21px;

    @media (max-width: 768px) {
      margin: 0;
    }
  }
}


.page-card {
  max-width: 1200px;
  margin: auto;
  z-index: 0;
  //overflow: hidden;
  //backface-visibility: hidden;

  &.no-header {
    margin-top: 51px;
  }
}

.page-footer {
  padding: 36px 0;
  text-align: center;
  color: #000;
  font-size: 14px;
}


label {
  font-size: 14px;
  color: #23404b;
  font-weight: 500;
}

select {
  background: #fff;
  margin-top: 5px;
  appearance: none !important;
  background: #fff url(../img/icons/drop.webp) 98% center no-repeat;
}

select::-ms-expand {
  display: none;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.btn {
  margin-left: 10px;
  padding: 17px 48px;
  position: relative;
  background: #007b01;
  border-radius: 25px;
  transition: 0.45s;
  z-index: 0;
  cursor: pointer;
  border: none;
}

.btn:before {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background: #007b01;
  border-radius: 25px;
  z-index: -1;
  left: 0;
  top: 0;
  transition: 0.3s;
  transition-timing-function: ease;
}

.btn span {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  transition: 0.3s;
  display: inline-block;
}

.btn:hover {
  background: rgba(0, 123, 1, 0.6);
}

.btn:hover span {
  transform: scale(0.9);
}

.btn:hover:before {
  background: rgba(0, 123, 1, 0.6);
  transform: scale(0);
}

.btn-trans,
.btn-white {
  height: 41px;
  line-height: 41px;
  font-size: 18px;
  border-radius: 21px;
  border: 2px solid transparent;
  text-align: center;
  color: #000;
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
  padding: 0 25px;
  transition: 0.3s;
}

.btn-white {
  font-size: 14px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.btn-trans:hover,
.btn-trans.active,
.btn-trans.router-link-exact-active {
  border-color: #ff9900;
  color: #ff9900;
}

.btn-blue,
.btn-grey,
.btn-black {
  cursor: pointer;
  font-size: 20px;
  border: none;
  line-height: 58px;
  height: 55px;
  color: #fff;
  font-weight: 500;
  border-radius: 27px;
  display: inline-block;
  padding: 0 40px;
  vertical-align: middle;
  margin: 0 10px;
  transition: 0.3s;
  background-color: #0078ff;
}

.btn-black {
  color: #fff;
  height: 37px;
  line-height: 37px;
  font-size: 13px;
  padding: 0px 25px;
  background-color: #000;
}

.btn-grey {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #d9d9d9;
}

.btn-grey:hover {
  background-color: #d0d0d0;
}

.btn-black:hover {
  background-color: #313131;
}

.btn-blue:hover {
  background-color: #00A5FF;
}

input,
textarea,
select {
  width: 100%;
  display: inline-block;
  border: 1px solid #cccccc;
  height: 42px;
  font-size: 20px;
  border-radius: 2px;
  color: #23404b;
  font-weight: 500;
  padding: 7px;
  margin-top: 5px;
}

.text-danger {
  color: #dc3545 !important;
  margin-bottom: 10px;
}

.border-danger {
  border-color: #dc3545 !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.sale-show {
  opacity: 1 !important;
}

.sale-out {
  opacity: 0;
}

.text-small {
  font-size: 0.7em;
  line-height: 1em;
}

.d-flex {
  display: flex;
}


@media (max-width: 1010px) {
  .wrap {
    padding: 0 3%;
  }

  .page-card {
    margin-left: 2%;
    margin-right: 2%;
    width: 96%;
  }
}

/* REFACTOR IN FUTURE */
.react-date-picker {
  display: block;
  background-color: #fff;
  width: 100%;

  .react-date-picker__button {
    width: 100%;
    border-color: #ccc;
  }

  .react-date-picker__button__input__month {
    width: auto !important;
    text-align: center;
  }

  .react-date-picker__button__input__day {
    width: auto !important;
    text-align: center;
  }

  .react-date-picker__button__input__year {
    width: auto !important;
    text-align: center;
  }
}


/* REACT PHONE-INPUT */

.react-phone-number-input {
  border: 1px solid #ccc;
  border-radius: 2px;

  &__country {
    margin-left: 10px;
  }

  &__input {
    border-bottom: none !important;
    border-left: 1px solid #ccc !important;
    margin: 0 !important;
    padding: 20px !important;
  }
}

button {
  outline: 0 !important;
}

button:focus {outline:0;}
*:focus {outline:none !important}

*{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}
