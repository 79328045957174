/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  display: flex;
  align-items: center; }

.Mark {
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #000;
  margin-right: 12px;
  background: #ffd657;
  border-radius: 100px;
  padding: 4px 8px; }
  .MarkWrap {
    display: flex;
    align-items: center;
    margin-bottom: 2px; }

.Text {
  font-size: 14px !important; }
