/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px 0;
  background: #faf8f5;
  z-index: 20;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: 0.1s; }

.Container {
  padding: 0 15px 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  position: relative; }
  @media (max-width: 575.8px) {
    .Container {
      padding: 0 10px 0; } }

.Logo {
  flex-basis: 20%;
  cursor: pointer; }
  @media (max-width: 1439.8px) {
    .Logo {
      flex-basis: unset; } }
  @media (max-width: 767.8px) {
    .Logo svg {
      height: 20px;
      width: auto; } }

.SidebarBtn {
  display: none; }
  @media (max-width: 1199.8px) {
    .SidebarBtn {
      display: block;
      margin-left: 30px; }
      .SidebarBtn svg {
        height: 20px;
        width: auto; } }
  @media (max-width: 767.8px) {
    .SidebarBtn svg {
      height: auto; } }

.Actions {
  flex-basis: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.SignBtn {
  margin-left: 40px;
  min-width: 151px;
  height: 45px;
  font-size: 16px;
  font-weight: 400; }

@media (max-width: 1199.8px) {
  .LanguageSwitcher {
    display: none; } }

.CreateLink {
  background: transparent;
  border-radius: 2px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  color: #575757;
  flex-shrink: 0;
  line-height: 28px;
  margin-right: 40px;
  font-size: 16px;
  border: 2px solid var(--accent-color); }
  @media (max-width: 767.8px) {
    .CreateLink {
      display: none !important; } }
