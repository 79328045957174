/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.orange {
  transform: scale(1);
  transition: transform .25s ease-in-out; }
  .orange:hover {
    background-color: var(--accent-color);
    border: 2px solid var(--accent-color);
    transform: scale(1.05); }

.line-price {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  background: var(--accent-color);
  color: #ffffff; }

.Component {
  box-shadow: 5px 6px 15px rgba(11, 12, 36, 0.04);
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative; }
  .Component_full {
    flex-direction: row; }
    .Component_full .Description {
      display: block; }
    .Component_full .Btn {
      width: 50%; }
    .Component_full .Main {
      align-items: flex-end; }
    @media (max-width: 1023.8px) {
      .Component_full {
        flex-direction: column; }
        .Component_full .Main {
          align-items: flex-start; }
        .Component_full .Description {
          display: none; }
        .Component_full .Btn {
          width: 100%; } }

.Description {
  font-size: 18px;
  line-height: 1.65em;
  color: #575757;
  margin: 0;
  display: none; }

.Image {
  min-width: 370px;
  max-width: 370px;
  height: 193px;
  background-color: #fff;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden; }
  @media (max-width: 575.8px) {
    .Image {
      height: 150px;
      min-width: unset;
      max-width: unset; } }

.wrap-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 767.8px) {
    .wrap-loader svg {
      transform: scale(0.7); } }

.Price {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 2px;
  color: #8c8c8c;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3em;
  transform: scale(0.75); }
  @media (max-width: 575.8px) {
    .Price {
      font-size: 12px; } }

.Main {
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  padding-top: 40px; }
  @media (max-width: 575.8px) {
    .Main {
      padding: 15px;
      padding-top: 30px; } }

.Info {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  flex-direction: column;
  justify-content: space-between; }
  @media (max-width: 575.8px) {
    .Info {
      margin-bottom: 10px; } }

.Title {
  font-family: var(--font-family-title);
  font-weight: bold;
  font-size: 22px;
  line-height: 1.4em;
  margin: 0 0 5px;
  color: #575757; }
  @media (max-width: 575.8px) {
    .Title {
      font-size: 16px; } }

.Param {
  font-size: 14px;
  line-height: 1.4em;
  margin: 0;
  margin: 0 0 5px;
  color: #575757; }
  .Param span {
    display: inline-flex;
    padding-top: 2px; }
  .Param svg {
    margin-right: 5px;
    margin-top: 5px;
    min-width: 20px; }
  @media (max-width: 575.8px) {
    .Param {
      font-size: 14px;
      display: flex; } }

.Calendar {
  display: flex;
  align-items: flex-start; }
  .Calendar svg {
    margin-top: 3px; }

.Img {
  height: 100%;
  width: 100%;
  object-fit: cover; }
