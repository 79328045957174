/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Page {
  flex-grow: 1;
  background: #faf8f5 url("assets/img/bg_poster_page_lines.svg") center 100px no-repeat;
  padding-top: 110px; }
  @media (max-width: 1023.8px) {
    .Page {
      padding-top: 70px; } }
  @media (max-width: 767.8px) {
    .Page {
      padding-top: 50px; } }
  @media (max-width: 575.8px) {
    .Page {
      padding-top: 10px; } }

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
    opacity: .71; }
  50% {
    transform: scale(1.1, 1.1);
    opacity: 1; }
  100% {
    opacity: .71;
    transform: scale(1, 1); } }

.keyframe {
  animation: zoominoutsinglefeatured 2s infinite; }

.Intro {
  padding: 0 15px 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  padding-top: 80px !important; }
  @media (max-width: 575.8px) {
    .Intro {
      padding: 0 10px 0; } }
  .IntroTitle {
    white-space: pre-wrap; }
    @media (max-width: 1023.8px) {
      .IntroTitle {
        margin-bottom: 40px; } }
  @media (max-width: 1023.8px) {
    .Intro {
      flex-direction: column;
      align-items: center; }
      .Intro img {
        max-width: 100%; } }

.Filters {
  padding: 0 15px 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: 30px; }
  @media (max-width: 575.8px) {
    .Filters {
      padding: 0 10px 0; } }
  @media (max-width: 1023.8px) {
    .Filters {
      grid-template-columns: 1fr; } }
  .FiltersSubmit {
    width: 190px;
    height: 60px;
    padding: 18px 0;
    font-weight: 600;
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 26px;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    transform: scale(1);
    transition: transform .25s ease-in-out; }
    .FiltersSubmit span {
      position: relative;
      z-index: 2; }
    .FiltersSubmit svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1; }
      .FiltersSubmit svg path {
        transition: 0.2s; }
    .FiltersSubmit:hover {
      transform: scale(1.05); }
    .FiltersSubmit[disabled],
    .FiltersSubmit:active svg path {
      cursor: not-allowed; }
    @media (max-width: 1023.8px) {
      .FiltersSubmitWrap {
        text-align: center; } }

.FiltersSatchel {
  position: relative;
  z-index: 2;
  top: -50px;
  background-color: #faf8f4;
  border-radius: 5px;
  padding: 16px 20px;
  box-shadow: 0px 1px 10px 0px #3333334f; }
  @media (max-width: 1200px) {
    .FiltersSatchel {
      top: 0;
      box-shadow: none;
      padding: 0 15px; } }

.Propose {
  background: url("assets/img/bg_poster_page_propose.jpg") center no-repeat;
  background-size: auto 100%;
  padding: 155px 15px;
  text-align: center;
  background-attachment: fixed; }
  .ProposeText {
    font-family: var(--font-family-title);
    font-weight: 700;
    font-size: 30px;
    line-height: 1.66em;
    margin: 0 0 70px;
    color: #fff;
    white-space: pre-wrap; }
    @media (max-width: 767.8px) {
      .ProposeText {
        font-size: 19px; } }
  .ProposeBtn {
    width: 100%;
    max-width: 370px; }
  @media (max-width: 1199.8px) {
    .Propose {
      padding: 120px 15px; } }
  @media (max-width: 1023.8px) {
    .Propose {
      padding: 80px 15px; } }
  @media (max-width: 575.8px) {
    .Propose {
      padding: 80px 10px; } }

.EventSection {
  padding: 60px 15px 60px;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  text-align: center; }
  @media (max-width: 575.8px) {
    .EventSection {
      padding: 60px 10px 60px; } }

.EventList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, 370px));
  grid-auto-rows: minmax(390px, auto);
  grid-gap: 30px;
  margin-bottom: 60px;
  justify-content: center;
  text-align: left; }
  @media (max-width: 767.8px) {
    .EventList {
      grid-auto-rows: auto; } }
  @media (max-width: 575.8px) {
    .EventList {
      grid-template-columns: 290px; } }

.EventBtn {
  width: 100%;
  max-width: 370px; }

.Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px; }
  .Loader svg {
    height: 50px;
    width: 50px; }
