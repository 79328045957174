.Component {
  background-color: #FFEFBD;
  height: 63px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 19;
}

.wrap {
  display: flex;
  align-items: center;
  height: 63px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.Text {
  color: black;
  font-size: 1rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

.Btn {
  font-size: 1.1rem;
  background-color: #FFD657;
  color: black;
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 40px;
  padding: 0 40px;
  margin-left: 40px;
  @media (max-width: 768px) {
    font-size: .8rem;
    margin-left: 0;
    margin-top: 4px;
    height: auto;
    padding: 5px 20px;


  }
}

