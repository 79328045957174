.Fields {
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  //max-width: 940px;
  padding: 30px;
  margin: 30px auto;
  background-color: #fff;
  @media (max-width: 780px) {
    grid-template-columns: 1fr;
  }
  @media(max-width: 480px) {
    padding: 16px;
  }
}