/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  display: flex;
  flex-direction: column;
  max-width: 460px; }
  .Component label {
    margin-bottom: 16px; }
    @media (max-width: 767.8px) {
      .Component label {
        margin-bottom: 14px; } }
  .Component input {
    padding: 20px;
    color: #575757;
    font-size: 18px;
    transition: all .25s ease-in-out;
    caret-color: var(--accent-color);
    margin: 0; }
    .Component input::placeholder {
      color: #979797;
      font-weight: normal; }
    .Component input:focus {
      border: 1px solid var(--accent-color);
      box-shadow: 0 0 5px 2px rgba(255, 214, 87, 0.21); }
  .Component_border-gray input {
    width: 100% !important;
    height: 60px;
    background-color: transparent;
    border: 1px solid #E2E4EA;
    border-radius: 2px !important; }
    @media (max-width: 767.8px) {
      .Component_border-gray input {
        height: 50px !important; } }
  .Component_border-gray label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #575757; }
  .Component_white input {
    background: white;
    height: 47px;
    border: 1px solid transparent; }
    .Component_white input:focus {
      border: 1px solid var(--accent-color); }
  .ComponentError input, .ComponentError input:focus {
    border: 1px solid #F61618;
    box-shadow: none;
    margin-bottom: 14px; }

.Loading {
  position: absolute;
  right: 10px;
  opacity: .5;
  top: calc(50% + 3px);
  transform: translateY(-50%); }

.WrapInput {
  position: relative; }
