/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  display: flex;
  cursor: pointer; }
  .Component input {
    display: none; }
  .Component label {
    display: flex;
    cursor: pointer; }
  .Component span {
    width: 18px;
    height: 18px;
    border: 1px solid var(--accent-color);
    display: inline-flex;
    flex-shrink: 0;
    border-radius: 50%;
    position: relative;
    margin-right: 12px; }
    .Component span:after {
      content: '';
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 7px;
      height: 7px;
      background-color: var(--accent-color);
      border-radius: 50%; }
  .Component input[type=radio]:disabled + label {
    color: var(--accent-color) !important;
    cursor: not-allowed;
    opacity: .6; }
  .Component input[type=radio]:checked + label span {
    border: 1px solid var(--accent-color);
    position: relative; }
    .Component input[type=radio]:checked + label span:after {
      opacity: 1 !important;
      transform: translate(-50%, -50%); }
