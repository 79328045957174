.typography {
  color: var(--black);
  line-height: 1.6em;
}

.typography--variant-label {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 15px;
  display: block;
}

.typography--variant-h1 {
  font-size: 2.6rem;
  font-weight: 500;
}

.typography--variant-h2 {
  font-size: 2.35rem;
  font-weight: 500;
}

.typography--variant-h3 {
  font-size: 2rem;
  font-weight: 500;
}

.typography--variant-h4 {
  font-size: 1.625rem;
  font-weight: 500;
}

.typography--variant-h5 {
  font-size: 1.5rem;
  font-weight: 500;
}

.typography--variant-h6 {
  font-size: 1.25rem;
  font-weight: 500;
}

.typography--variant-subheading1 {
  font-size: 1rem;
  font-weight: 500;
}

.typography--variant-subheading2 {
  font-size: 0.875rem;
  font-weight: 500;
}

.typography--variant-body {
  font-size: 1rem;
}

.typography--variant-small {
  font-size: 0.875rem;
}

.typography--variant-400 {
  font-weight: 400;
}

.typography--variant-500 {
  font-weight: 500;
}

.typography--variant-600 {
  font-weight: 600;
}
