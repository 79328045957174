/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.banner {
  margin: 14px auto 15px;
  overflow: hidden;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  padding-bottom: 54%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  .banner:after {
    top: 50%;
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    left: 0;
    transform: translateX(-50%);
    background-color: #FAF8F5;
    border-radius: 50%;
    content: ''; }
  .banner:before {
    top: 50%;
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    right: 0;
    transform: translateX(50%);
    background-color: #FAF8F5;
    border-radius: 50%;
    content: ''; }
  .banner img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  @media (max-width: 575.8px) {
    .banner {
      width: calc(100% + 32px);
      left: -16px;
      margin-top: 0; }
      .banner:after, .banner:before {
        content: none; } }
