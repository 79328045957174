/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  position: relative;
  margin-left: 5px; }

.TicketButton {
  width: 200px;
  height: 60px;
  margin-left: 30px;
  font-weight: 600;
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 26px;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  transform: scale(1);
  transition: transform .25s ease-in-out; }
  .TicketButton span {
    position: relative;
    z-index: 2; }
  .TicketButton svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
    .TicketButton svg path {
      transition: 0.2s; }
  .TicketButton:hover {
    transform: scale(1.05); }
  .TicketButton[disabled],
  .TicketButton:active svg path {
    cursor: not-allowed; }
  @media (max-width: 1439.8px) {
    .TicketButton {
      width: 220px;
      height: 50px; } }
  @media (max-width: 767.8px) {
    .TicketButton {
      width: 135px;
      height: 30px;
      font-size: 10px; } }
  @media (max-width: 575.8px) {
    .TicketButton {
      margin-left: 0px; } }

.Wrap {
  display: flex;
  align-items: center; }

.Control {
  display: flex;
  padding-left: 15px;
  margin-left: 10px;
  border-left: 1px solid var(--light-accent-color);
  align-items: center; }
  .Control svg {
    margin-left: 14px; }
  @media (max-width: 767.8px) {
    .Control {
      margin-left: 10px;
      flex-direction: row-reverse; }
      .Control p {
        font-size: 14px;
        padding-left: 10px; }
      .Control svg {
        margin-left: 0;
        margin-right: 4px; } }
  @media (max-width: 575.8px) {
    .Control {
      padding-right: 0;
      padding-left: 10px; } }

.Avatar {
  width: 32px !important;
  height: 32px !important;
  margin-right: 10px !important; }
  @media (max-width: 767.8px) {
    .Avatar {
      margin-right: 0 !important; } }

.Content {
  z-index: 5;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  padding: 12px 0;
  background: #FFFFFF;
  overflow: auto;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px;
  left: unset;
  right: 0;
  top: 60px;
  cursor: pointer;
  background-color: #fff;
  font-size: 16px;
  line-height: 24px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
  min-width: 240px; }
  .Content button, .Content a {
    padding: 0;
    text-align: left;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 16px !important;
    color: #333; }

.Icon {
  display: flex;
  align-items: center;
  width: 25px;
  margin-right: 10px; }

.Divider {
  height: 1px;
  background-color: rgba(51, 51, 51, 0.2); }

.CreateLink {
  border: 2px solid var(--accent-color);
  height: 40px;
  text-align: center;
  justify-content: center; }

.Landscape {
  display: none !important; }
  @media (max-width: 767.8px) {
    .Landscape {
      display: flex !important; } }
