@import '~react-phone-input-2/dist/style.css';
//@import url('https://fonts.googleapis.com/css?family=Comfortaa:400,700|Open+Sans:300i,400i,400,600,700,700i');
@import './variables.scss';

* {
  font-family: var(--font-family);

}

body {
  font-family: var(--font-family);
  color: $color-black;
}

h1 {
  font-family: var(--font-family-title);

}

.container {
  @include container;
  @include phone {
    &.containerInvent {

      padding: 0 16px;
    }
  }
}

input, select, textarea, a, button {
  font-family: inherit;
}

a, button {
  cursor: pointer;
  transition: 0.2s;
}

h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.66em;
  margin: 0;

  @include landscape {
    font-size: 19px;
  }
}

h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.6em;
  margin: 0;

  @media (max-width: $breakpoint-tablet) {
    font-size: 16px;
  }
}

h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.85em;
  margin: 0;
}

h4 {
  font-weight: 600;
  font-size: 17px;
  line-height: 1.65em;
  margin: 0;
}

p {
  line-height: 1.66em;
  margin: 0;
  font-size: 18px;
  color: $color-black;

  @media (max-width: $breakpoint-tablet) {
    font-size: 14px;
  }
}

p.small {
  line-height: 1.75em;
  font-size: 16px;
}


svg path {
  transition: fill .35s ease;
}

.hide {
  position: absolute;
  opacity: 0;
  left: -10000px;
  visibility: hidden;
}

.one-line {
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1; /* number of lines to show */
  color: #212121;
  flex-shrink: 0;
}

@import '~scss/other.scss';
