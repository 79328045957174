@import '~scss/variables.scss';

.UploadFile {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.text-error {
  position: relative;
  margin-bottom: 0;
  font-size: 0.8rem;
}


.Component {
  width: 100%;
  position: relative;
  border: 1px dashed var(--accent-color);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  background-color: #fff;
  color: $color-black;
  cursor: pointer;
  font-size: 25px;
  font-weight: bold;
  flex-direction: column;


  input {
    opacity: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.Close {
  position: absolute;
  right: -16px;
  z-index: 10;
  top: -16px;
}

.Text {
  font-size: 12px;
  margin-top: 10px;
  padding: 0 10px;
}