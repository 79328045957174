
.Component {
  margin-top: 5px;
  width: 100%;
  &Input {
    width: 100%!important;
    height: 42px!important;
    border-radius: 2px!important;
    border: 1px solid #cccccc!important;
    height: 42px!important;
    font-size: 20px!important;
    border-radius: 2px!important;
    color: #23404b!important;
    font-weight: 500!important;
    padding: 7px 7px 7px 48px!important;
  }
}
.container {

}



.button {

}
.dropdown {

}
.search {

}
@import '~scss/variables.scss';
.defaultInput{
  border: 1px solid #8C8C8C!important;
  box-sizing: border-box;
  border-radius: 2px!important;
  width: 100%;
  height: 46px!important;
  display: flex;
  align-items: center;
  @include phone{
    width: 100%;
    //height: 30px!important;
    font-size: 16px!important;
  }
}

@import '~scss/inputRestyles.scss';

