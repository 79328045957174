.Component {
  display: flex;
  align-items: center;
}

.Avatar {
  position: relative;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 10em;
  margin-right: 12px;
  background-color: #e7e5e5;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.Label {
  font-size: 16px;
}