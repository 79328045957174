/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  box-shadow: 0px -3px 10px rgba(226, 228, 234, 0.4), 0px 10px 40px rgba(226, 228, 234, 0.45);
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  position: relative; }
  @media (max-width: 575.8px) {
    .Component {
      padding: 20px; } }

.Ticket {
  overflow: hidden;
  position: relative;
  background-color: white;
  padding: 20px 45px;
  border-radius: 20px;
  box-shadow: 0px -3px 10px rgba(226, 228, 234, 0.4), 0px 10px 40px rgba(226, 228, 234, 0.45); }
  @media (max-width: 575.8px) {
    .Ticket {
      padding: 20px 15px; } }
  .Ticket .Circle {
    width: 54px;
    height: 54px;
    background-color: rgba(226, 228, 234, 0.4);
    border-radius: 50%;
    position: absolute;
    overflow: hidden; }
    @media (max-width: 575.8px) {
      .Ticket .Circle {
        width: 20px;
        height: 20px; } }
  .Ticket .Right {
    top: 50%;
    transform: translateY(-50%);
    right: -30px; }
    @media (max-width: 575.8px) {
      .Ticket .Right {
        right: -10px; } }
  .Ticket .Left {
    top: 50%;
    transform: translateY(-50%);
    left: -30px; }
    @media (max-width: 575.8px) {
      .Ticket .Left {
        left: -10px; } }
