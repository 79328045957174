/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Nav {
  text-align: center;
  flex-grow: 1;
  margin-top: 5px; }
  .Nav a {
    color: #575757;
    line-height: 28px;
    font-size: 16px;
    margin-right: 40px;
    border-bottom: 1px solid transparent; }
    .Nav a:last-child {
      margin-right: 0; }
    .Nav a:hover {
      border-bottom-color: var(--accent-color); }
  @media (max-width: 1199.8px) {
    .Nav {
      display: none; } }
